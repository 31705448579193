import pLimit from 'p-limit';

import BaseCache from './BaseCache';
import {get_form_submissions, update_submission, delete_submission, create_submission} from 'api/zero-api';
import {processAttachments, uploadSubmissionAttachment} from './utils';
import {checkForDebugErrorUpload, extractErrorMessages, generateUUID, getErrorMessageFromResponse, getReduxState, objectIsEmpty, withRetry} from 'other/Helper';
import { useEffect, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { DebugLogger } from 'other/DebugLogger';
import { reportError } from 'other/errorReporting';

const debugLogger = new DebugLogger('SubmissionDraftsCache');

/**
 * Returns an array that contains all attachments from all fields in a submission draft.
 * @param {object} draft
 * @returns {object[]}
 */
export function getAttachments(draft) {
    let attachments = [];

    for (const field of draft.fields) {
        const attachmentCount = field.attachments?.length || 0;
        if (attachmentCount === 0) {
            continue;
        }
        attachments = [...attachments, ...field.attachments];
    }

    for (const field of draft.form.fields) {
        const attachmentCount = field.attachments?.length || 0;
        if (attachmentCount === 0) {
            continue;
        }
        attachments = [...attachments, ...field.attachments];
    }

    return attachments;
}


/**
 * Gets forms from API and overwrites existing DB with results.
 * @param {SubmissionDraftsCache} cache
 * @param {function} afterSyncCallback
 * @param {PostService} postService
 */
export async function syncSubmissionDrafts(cache, afterSyncCallback, postService) {
    if (!cache) return;
    try {
        const dlog = debugLogger.branch('syncSubmissionDrafts');
        const params = new URLSearchParams();
        params.set('form_types', 'regular,post_embedded');
        params.set('drafts', 'only');
        params.set('my_submissions', 'true');
        params.set('team_uuid', 'my_teams');

        const res = await get_form_submissions(`?${params}`);
        const content = await res.json();
        if (content) {
            const submissions = content.submissions;
            await cache.setAll(submissions);

            for (const submission of submissions) {
                const attachments = getAttachments(submission);
                await processAttachments(cache, submission.submission_uuid, attachments);

                if (submission.parent_post_uuid) {
                    dlog('submission.parent_post_uuid:', submission.parent_post_uuid);
                    try {
                        const post = await postService.getPost(submission.parent_post_uuid);
                        dlog('post:', post);
                        if (post.status !== 'draft') {
                            if (submission.$offline) {
                                await cache.delete(submission.submission_uuid, {includeAttachments: true, immediate: true});
                            }
                        } else if (post && post.embedded_forms?.length && post.$meta.unsyncedEmbeddedForms?.length) {
                            const unsyncedEmbeddedForms = post.$meta.unsyncedEmbeddedForms;
                            const offlineSubmission = await cache.get(unsyncedEmbeddedForms[0].submission_uuid);
                            dlog('offlineSubmission:', offlineSubmission);
                            if (!objectIsEmpty(offlineSubmission)) {
                                dlog(`Setting fields`);
                                await cache.updateDraft(submission.submission_uuid, {
                                    fields: offlineSubmission.fields,  
                                });
                                dlog(`Deleting offline submission`);
                                await cache.delete(offlineSubmission.submission_uuid, {includeAttachments: false, immediate: true});
                            }
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }
            }

            await syncLocalToRemote(cache);
            await afterSyncCallback();
        } else {
            throw new Error('content is empty');
        }
    } catch (error) {
        console.error('Could not sync submission drafts:', error);
    }
}

export const draftsCurrentlySyncing = {
    ids: [],
    count: 0,
    eventTarget: new EventTarget(),
    add(id) {
        this.eventTarget.dispatchEvent(new CustomEvent("change", {detail: { id, action: "add" }}));
        this.ids = [...this.ids, id];
        this.count = this.ids.length;
    },
    remove(id) {
        this.eventTarget.dispatchEvent(new CustomEvent("change", {detail: { id, action: "remove" }}));
        this.ids = this.ids.filter(_id => _id !== id);
        this.count = this.ids.length;
    },
    has(id) {
        return this.ids.includes(id);
    },
    /**
     * @param {(event: CustomEvent) => any} callback 
     * @returns {() => void}
     */
    subscribe(callback) {
        this.eventTarget.addEventListener("change", callback);
        return () => {
            this.eventTarget.removeEventListener("change", callback);
        }
    }
}

export function useDraftsCurrentlySyncing() {
    const [ids, setIds] = useState([]);
    const [count, setCount] = useState(0);
    const subscribeRef = useRef(draftsCurrentlySyncing.subscribe.bind(draftsCurrentlySyncing));

    useEffect(() => {
        setIds(draftsCurrentlySyncing.ids);
    }, [draftsCurrentlySyncing.ids]);

    useEffect(() => {
        setCount(draftsCurrentlySyncing.count);
    }, [draftsCurrentlySyncing.count]);

    return {
        ids,
        count,
        add: draftsCurrentlySyncing.add,
        remove: draftsCurrentlySyncing.remove,
        /** @type {(callback: (event: CustomEvent) => any) => () => void} */
        subscribe: subscribeRef.current,
    };
}

/**
 * Gets forms from API and overwrites existing DB with results.
 * @param {SubmissionDraftsCache} cache
 * @param {BaseCache_Document} submission
 * @param {{forceSync?: boolean, forceSubmission?: boolean}} options
 */
export async function syncLocalDraft(cache, submission, options = {}) {
    const {forceSync = false, forceSubmission = false} = options;

    const dlog = debugLogger.branch('syncLocalDraft');
    let submissionId = submission.submission_uuid;
    const offlineId = submissionId.startsWith("offline:") ? submissionId : null;
    dlog('submissionId:', submissionId);
    const syncId = submissionId;
    if (draftsCurrentlySyncing.has(syncId)) {
        dlog('already syncing', submissionId);
        return;
    }
    draftsCurrentlySyncing.add(syncId);

    if (forceSubmission) {
        submission.$submitted = true;
    }

    try {
        if (submissionId === undefined) {
            await cache.db.remove(submission);
        } else if (submission.$error === 'sync-conflict') {
            console.debug(`Error with sync ${submissionId} - ${submission.$error}`);
        } else if (submission.$deleted) {
            dlog("submission has been deleted");
            try {
                if (!submission.$offline) {
                    // need to delete from remote
                    await delete_submission(submissionId);
                }
                await cache.db.remove(submission);
    
                cache.target.dispatchEvent(new CustomEvent('draftChange', {
                    detail: {
                        currentId: submission._id,
                        newId: null
                    }
                }));
            } catch (err) {
                let errorMessage = err.toString();
                if (err instanceof Response) {
                    errorMessage = await getErrorMessageFromResponse(err);
                }
                reportError('Could not delete submission draft', {
                    id: submission._id,
                    error: err,
                    errorMessage,
                })
                await cache.setProperties(submissionId, {$error: 'delete', $errorMessage: errorMessage});
                return err;
            }
        } else if (submission.$offline || submission.$updated || submission.$submitted || forceSync) {
            dlog("submission is offline or updated or submitted");
            if (submission.parent_post_uuid && submission.$offline) {
                // offline submission is part of a custom post form, don't sync
                dlog("offline submission is part of a custom post form, don't sync");
                return;
            }
            if (submission.$offline) {
                try {
                    const body = {
                        team_uuid: submission.team.uuid,
                        form_uuid: submission.form.form_uuid,
                        created_at: submission.created_at,
                        submission_uuid: submission.submission_uuid.replace("offline:", ""),
                    }

                    const response = await create_submission(JSON.stringify(body));
                    const content = await response.json();
                    const newSubmission = content.submission;
    
                    for (const prop in newSubmission) {
                        if (prop !== 'fields' && prop !== 'assignment_uuid') {
                            submission[prop] = newSubmission[prop];
                        }
                    }
    
                    submissionId = newSubmission.submission_uuid
                } catch (err) {
                    let message = err.toString();
                    if (err instanceof Response) {
                        message = await getErrorMessageFromResponse(err);
                    }
                    reportError('Could not create remote version of offline draft', {
                        id: submission._id,
                        error: err,
                        errorMessage: message,
                    });
                    await cache.setProperties(submissionId, {$error: 'create', $errorMessage: message});
                    return err;
                }
            }
    
            if (submission.fields.length === 0 && !submission.parent_post_uuid) {
                if (submission.$offline && submission._id !== submission.submission_uuid) {
                    await cache.delete(submission._id, {includeAttachments: true, immediate: true});
                    
                    cache.target.dispatchEvent(new CustomEvent('draftChange', {
                        detail: {
                            currentId: submission._id,
                            newId: submissionId
                        }
                    }));
                }
    
                return;
            }
    
            let isUpdateError = false;
            let isSubmitError = false;
    
            try {
                const limit = pLimit(10);
                const promises = [];
                for (const field of submission.fields) {
                    promises.push(...field.attachments.map(attachment => limit(async () => {
                        if (attachment.attachment_uuid?.startsWith('offline:')) {
                            const callback = async () => {
                                const blob = cache.blobs[attachment.attachment_uuid];
                                if (!blob) {
                                    return;
                                }
                                const {
                                    file_path,
                                    public_url
                                } = await uploadSubmissionAttachment(submission, field, attachment, blob.blob);
                                attachment.file_path = file_path;
                                attachment.public_url = public_url;
                                delete attachment['attachment_uuid'];
                            }
                            
                            await withRetry(3, 100, callback);
                        }
                    })));
                }
    
                await Promise.all(promises);
    
                isUpdateError = true;
    
                const body = {
                    commit: false,
                    fields: submission.fields,
                    manual_edited_at: submission.$editedAt || submission.edited_at
                };

                dlog("update body:", body);
    
                if (submission.assignment_uuid) {
                    body.assignment_uuid = submission.assignment_uuid;
                }
    
                if (submissionId.startsWith("offline:")) {
                    console.error("submission id should not start with 'offline' at this point");
                    return;
                }

                if (window.zeroThrowSubmissionSyncError) {
                    throw new Error("Sync Test Error");
                }

                const response = await update_submission(submissionId, JSON.stringify(body));
                const data = await response.json();
                const updatedSubmission = data.submission;
                if (submission.$submitted) {
                    updatedSubmission.$submitted = true;
                }
    
                await cache.set(submissionId, updatedSubmission);
                if (submission.$offline) {
                    await cache.delete(submission._id, {includeAttachments: true, immediate: true});
                }
    
                cache.target.dispatchEvent(new CustomEvent('draftChange', {
                    detail: {
                        currentId: submission._id,
                        newId: submissionId
                    }
                }));

                const attachments = getAttachments(updatedSubmission);
                await processAttachments(cache, submissionId, attachments);
    
                if (updatedSubmission.$submitted) {
                    isSubmitError = true;
                    body.manual_edited_at = updatedSubmission.edited_at;
                    body.commit = true;
                    await update_submission(submissionId, JSON.stringify(body));
                    await cache.delete(submissionId, {includeAttachments: true, immediate: true});
                    submissionId = null;
                }
            } catch (err) {
                let networkLossError = false;
                let errorMessage;

                if (err instanceof TypeError || err instanceof AxiosError) {
                    // Went offline during sync
                    console.error("Network loss during sync");
                    networkLossError = true;
                    errorMessage = err.toString();
                } else if (err instanceof Response) {
                    errorMessage = await getErrorMessageFromResponse(err);
                } else {
                    errorMessage = err.toString();
                }
                
                console.error(`Could not sync submission ${submissionId}: ${errorMessage}`);

                const errorDetails = {
                    id: submissionId,
                    offlineId,
                    error: err,
                    isUpdateError,
                    isSubmitError,
                }

                reportError("Submission Sync Error", {
                    ...errorDetails,
                    errorMessage,
                    stack: err.stack,
                });

                cache.target.dispatchEvent(new CustomEvent('syncError', {
                    detail: errorDetails
                }));
    
                if (submission._id.startsWith('offline:') && submission._id !== submissionId) {
                    if (isUpdateError) {
                        try {
                            const updatedSubmission = {};
                            for (const prop in submission) {
                                if (!prop.startsWith('_')) {
                                    updatedSubmission[prop] = submission[prop];
                                }
                            }
                            delete updatedSubmission.$offline;
                            delete updatedSubmission.$deleted;
                            updatedSubmission.has_been_updated = true;
                            updatedSubmission.$updated = true;
                            updatedSubmission.$error = isSubmitError ? 'submit' : 'update';
                            updatedSubmission.$errorMessage = errorMessage;
    
                            if (isSubmitError) {
                                updatedSubmission.edited_at = updatedSubmission.$editedAt;
                                delete updatedSubmission.$editedAt;
                            }
    
                            await cache.set(submissionId, updatedSubmission);
                            await cache.delete(submission._id);
                            
                            cache.target.dispatchEvent(new CustomEvent('draftChange', {
                                detail: {
                                    currentId: submission._id,
                                    newId: submissionId
                                }
                            }));
                        } catch (err) {
                            console.error("Could not swap offline/online draft:", err);
                            return err;
                        }
                    } else {
                        try {
                            await delete_submission(submissionId);
                        } catch (err) {
                            console.error('Could not delete orphaned draft:', submissionId);
                            return err;
                        }
                    }
                } else {
                    const updatedProperties = {
                        $error: isSubmitError ? 'submit' : 'update',
                        $errorMessage: errorMessage,
                    };
                    if (isSubmitError) {
                        updatedProperties.$editedAt = undefined;
                    }
                    if (!networkLossError) {
                        updatedProperties.$submitted = false;
                    }
                    await cache.setProperties(submission._id, updatedProperties);
                    return err;
                }
                cache.target.dispatchEvent(new CustomEvent('draftChange', {
                    detail: {
                        currentId: submission._id,
                        newId: null
                    }
                }));
            }
        } else {
            dlog("submission", submissionId, "does not need to be synced");
        }

        // no errors
        return null;
    } finally {
        draftsCurrentlySyncing.remove(syncId);
    }
}

/**
 * @param {SubmissionDraftsCache} cache
 */
async function syncLocalToRemote(cache) {
    const offlineSubmissions = await cache.getAll();

    for (const submission of offlineSubmissions) {
        await syncLocalDraft(cache, submission);
    }
}

export default class SubmissionDraftsCache extends BaseCache {
    /**
     * @param {string} userId
     */
    constructor(orgId, userId) {
        super('form_drafts', 'submission_uuid', orgId, userId);

        this.target = new EventTarget();

        if (window.zeroDebugDestroyFormDraftsDb === undefined) {
            window.zeroDebugDestroyFormDraftsDb = () => {
                this.db.destroy();
                window.location.reload();
            }
        }

        if (window.zeroDebugSetDraftError === undefined) {
            window.zeroDebugSetDraftError = async (draftId, error) => {
                await this.updateDraft(draftId, {$error: error})
            }
        }

        if (window.zeroDebugUpdateDraft === undefined) {
            window.zeroDebugUpdateDraft = async (draftId, properties) => {
                await this.updateDraft(draftId, properties ?? {})
            }
        }

        this.onChangeCallbacks = [];
        this.attachmentQueue = [];
        this.isSavingAttachments = false;
    }

    onChange(callback) {
        this.onChangeCallbacks = [
            ...this.onChangeCallbacks,
            callback
        ];
    }

    async updateDraft(id, updatedProperties) {
        const currentDoc = await this.get(id);

        if (Object.keys(currentDoc).length === 0) {
            throw new Error({code: 'does-not-exist', message: 'Draft does not exist.'});
        }

        const newDoc = {
            ...currentDoc,
            ...updatedProperties,
            $editedAt: Date.now() / 1000,
        };

        if (!newDoc.$offline) {
            newDoc.$updated = true;
        } else {
            newDoc.has_been_updated = true;
        }

        await this.set(id, newDoc);
        return this.get(id);
    }

    async setProperties(id, updatedProperties) {
        const currentDoc = await this.get(id);
        const newDoc = {
            ...currentDoc,
            ...updatedProperties,
        };
        await this.set(id, newDoc);
        return this.get(id);
    }

    async syncDraft(id, submission) {
        await this.set(id, {
            ...submission,
            $syncedAt: Date.now() / 1000,
        });
        await this.processAttachments(id);
    }

    async processAttachments(submissionId) {
        const submission = await this.get(submissionId);
        const attachments = getAttachments(submission);
        await processAttachments(this, submissionId, attachments);
    }

    async saveOfflineAttachment(draftId, file) {
        const attachmentId = `offline:${generateUUID()}`;
        const url = URL.createObjectURL(file);

        const attachment = {
            attachment_uuid: attachmentId,
            file_path: url,
            file_name: file.name,
            public_url: url,
            mime_type: file.type,
        }

        const eventName = "attachment-processed";

        const promise = new Promise((resolve, reject) => {
            const onAttachmentProcessed = (event) => {
                if (event.detail?.attachmentId === attachmentId) {
                    this.target.removeEventListener(eventName, onAttachmentProcessed);
                    if (event.detail?.error) {
                        reject(event.detail.error);
                    } else {
                        resolve(attachment);
                    }
                }
            }
            this.target.addEventListener(eventName, onAttachmentProcessed);
        })

        this.attachmentQueue.push([draftId, file, attachmentId, url]);

        if (!this.isSavingAttachments) {
            this.isSavingAttachments = true;

            setTimeout(async () => {
                while (this.attachmentQueue.length > 0) {
                    const [draftId, file, attachmentId, url] = this.attachmentQueue.splice(0, 1)[0];
                    try {
                        checkForDebugErrorUpload(file.name);
                        const callback = async () => {
                            let doc = await this.get(draftId);
                            await this.db.putAttachment(draftId, attachmentId, doc._rev, file, file.type);
            
                            this.blobs = {
                                ...this.blobs,
                                [attachmentId]: {
                                    blob: file,
                                    url,
                                }
                            }

                            this.target.dispatchEvent(
                                new CustomEvent(eventName, {
                                    detail: {
                                        attachmentId
                                    }
                                })
                            );
                        }

                        await withRetry(5, 100, callback);
                    } catch (err) {
                        this.target.dispatchEvent(
                            new CustomEvent(eventName, {
                                detail: {
                                    attachmentId,
                                    error: err
                                }
                            })
                        );
                    }
                }
                this.isSavingAttachments = false;
            }, 250);
        }

        return promise;
    }

    createUpdatedDocument(oldDoc, newData, $syncedAt) {
        const newDoc = super.createUpdatedDocument(oldDoc, newData, $syncedAt);

        if (oldDoc.$updated) {
            if (newDoc.edited_at > oldDoc.edited_at) {
                return {
                    ...oldDoc,
                    $error: 'sync-conflict',
                }
            } else {
                return oldDoc;
            }
        }

        return newDoc;
    }

    async delete(id, options = {immediate: false, includeAttachments: false}) {
        const {immediate, includeAttachments} = options;
        let draft = await this.get(id);
        if (!draft._id) return;

        let attachmentIds = [];

        if (includeAttachments) {
            try {
                const attachments = getAttachments(draft);
                attachmentIds = attachments.map(a => a.attachment_uuid);
            } catch (err) {
                console.error('Could not get attachments for offline draft:', id, err);
            }

            for (const attachmentId of attachmentIds) {
                try {
                    await this.deleteAttachment(id, attachmentId);
                } catch (err) {
                    console.error('Could not delete offline draft attachment:', id, attachmentId);
                }
            }
        }

        if (!immediate) {
            if (includeAttachments) {
                // need to get latest version from DB
                draft = await this.get(id);
            }
            draft.$deleted = true;
            await this.set(id, draft);
        } else {
            try {
                draft = await this.get(id);
                this.db.remove(draft);
            } catch (err) {
                console.error('Could not delete offline draft:', id);
            }
        }
    }

    /**
     *
     * @param {{}} form
     * @param {{}} team
     * @param {{}} user
     * @param {string?} assignmentId
     * @returns {string}
     */
    async createDraft(form, team, user, assignmentId = null) {
        try {
            const now = Date.now() / 1000;
            const id = `offline:${generateUUID()}`;

            const draft = {
                _id: id,
                $offline: true,
                submission_uuid: id,
                form,
                team,
                fields: [],
                created_by: user,
                created_at: now,
                edited_by: user,
                edited_at: now,
                score: '--',
                scheduler_name: '',
                shared_teams: [],
                comment_count: 0,
                failed_items_count: null,
                reference_number: id.substring(8, 12),
                progress: 0,
                draft: true,
                assignment_uuid: assignmentId,
                has_been_updated: false,
            }

            await this.set(id, draft);

            return id;
        } catch (err) {
            console.error('Could not create offline draft', err);
        }
    }
}
