// @ts-check

import { get_form_submissions } from "api/zero-api";
import { SubmissionActionCell, SubmissionCommentsCell, SubmissionNameCell, SubmissionStatusTag } from "./SubmissionDataGrid.cells";
import { FormType } from "other/Constants";
import { AgGridFilter } from "other/agGridHelper";
import { dateFormatterNoTime, formatName } from "other/Helper";

export const EventNames = {
    // Emitted when "Reset table" is clicked. Resets sorting and column order.
    RESET_TABLE: 'SubmissionDataGrid:resetTable',
    // Emitted when table should refetch datasource
    REFRESH_DATA: 'SubmissionDataGrid:refreshData',
}

/**
 * @returns {AgGrid.GridOptions['columnDefs']}
 */
export function getColumnDefs() {

    /** @type {AgGrid.GridOptions['columnDefs']} */
    let defs = [
        {
            headerName: "",
            field: "_actions_",
            width: 50,
            cellRenderer: SubmissionActionCell,
            resizable: false,
            sortable: false,
            suppressMovable: true,
            lockPosition: "left",
            cellStyle: {
                padding: 0,
            }
        },
        {
            headerName: "Name",
            field: "created_by",
            cellRenderer: SubmissionNameCell,
            valueFormatter: ({value}) => formatName(value, {defaultName: ""}),
            ...AgGridFilter.textNoBlankOptions,
        },
        {
            headerName: "Submitted On",
            field: "created_at",
            valueFormatter: ({ value }) => value ? dateFormatterNoTime(value) : "",
            cellClass: "dateFormatterNoTime",
            initialSort: 'desc',
            ...AgGridFilter.date,
        },
        {
            headerName: "Template",
            field: "agFormName",
            wrapText: true,
            cellClass: 'ag-grid-normal-lineheight',
            ...AgGridFilter.textNoBlankOptions,
        },
        {
            headerName: "Schedule",
            field: "scheduler_name",
            wrapText: true,
            cellClass: 'ag-grid-normal-lineheight',
            ...AgGridFilter.textNullable,
        },
        {
            headerName: "Team",
            field: "agTeamName",
            ...AgGridFilter.textNoBlankOptions,
        },
        {
            headerName: "Score",
            field: "score",
            width: 125,
            valueFormatter: ({value}) => {
                if (!value) return "";
                if (value === "--") return value;
                return value + "%";
            },
        },
        {
            headerName: "Status",
            field: "status",
            width: 120,
            cellRenderer: SubmissionStatusTag,
            valueFormatter: ({value}) => {
                if (Object.keys(SubmissionStatusMap).includes(value)) {
                    return SubmissionStatusMap[value];
                }
                return "";
            }
        },
        {
            headerName: "Comments",
            field: "comment_count",
            cellRenderer: SubmissionCommentsCell,
            width: 120,
        },
    ];

    return defs;
}

/**
 * @param {URLSearchParams} queryParams 
 * @returns {Promise<{submissions: any[], totalSubmissions: number}>}
 */
export async function loadSubmissions(queryParams) {
    const res = await get_form_submissions(`?${queryParams}`);
    const { submissions: rawSubmissions, total_submissions: totalSubmissions } = await res.json();

    const submissions = rawSubmissions.map(submission => {
        return {
            ...submission,
            agFormName: submission.form.name,
            agTeamName: submission.team.name,
        }
    });

    return {submissions, totalSubmissions};
}

/**
 * 
 * @param {any} submission 
 * @param {string} orgId 
 * @param {{forEdit?: boolean}} options 
 */
export function getSubmissionLink(submission, orgId, options = {}) {
    const {forEdit = false} = options;
    const formTypePath = submission.form.form_type === FormType.REGULAR ? "forms" : "courses";
    if (forEdit) {
        return `/${orgId}/home/team/${submission.team.uuid}/${formTypePath}/${submission.form.form_uuid}/submission/${submission.submission_uuid}?edit_submission=true`;
    }
    return `/${orgId}/home/team/${submission.team.uuid}/${formTypePath}/submission_view/${submission.submission_uuid}`;
};

export const SubmissionStatusMap = {
    "submitted": "Submitted",
    "in_review": "In Review",
    "closed": "Closed",
};