// @ts-check
import { Alert, Radio, Result, Space } from "antd";
import { AlertOutlined } from '@ant-design/icons';
import { logoutAndCleanupState, publicGetForm, publicGetSubmission, publicSubmitForm, publicUpdateSubmission } from "api/zero-api";
import FormFieldsRenderer from "components/Forms/FormFieldsRenderer";
import FormSubmissionControls from "components/Forms/FormSubmissionControls";
import { PageSelectDropdown } from "components/Forms/PageSelectDropdown";
import PublicFormConfirmation from "components/Forms/PublicFormConfirmation";
import { BorderedPanel, MainContent, PageContent, PageHead } from "components/Shared/PageParts";
import SimpleModal from "components/Shared/SimpleModal";
import { useAsyncDataLoader } from "hooks/useAsyncDataLoader";
import useOrganizationId from "hooks/useOrganizationId";
import DirectoryCache from "offline/DirectoryCache";
import DynamicListCache from "offline/DynamicListCache";
import { FormType } from "other/Constants";
import { checkForDebugErrorUpload, extractErrorMessages, getErrorMessageFromResponse, getQueryParam, isPublicUser, publicFileUpload } from "other/Helper";
import NotificationAlert from "other/NotificationAlert";
import { generateFormPages, submissionHasBlankRequiredFields } from "other/forms";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router";
import { getStore } from "store/store";
import { usePublicContext } from "../PublicContext";
import useZeroSelector from "hooks/useZeroSelector";
import ConfirmModal from "components/Shared/ConfirmModal";
import SaveDraftModal from "./SaveDraftModal";

class SubmissionPublishedError extends Error {};

async function uploadPublicAttachments(answers, formId) {
    const queue = [];
    for (const answer of answers) {
        for (const attachment of answer.attachments) {
            if (!attachment.file) {
                // attachment has already been uploaded
                continue;
            }
            const params = {
                feature_key: "forms_submission",
                form_uuid: formId,
                field_uuid: answer.id,
                content_type: attachment.file.type,
                file_name: attachment.file.name,
                embedded: false
            }

            queue.push(new Promise((resolve, reject) => {
                try {
                    checkForDebugErrorUpload(attachment.file.name);
                } catch (error) {
                    attachment.error = true;
                    reject({error, attachment});
                    return;
                }
                publicFileUpload(
                    params,
                    attachment.file,
                    () => {}, // progress
                    (preSignedUrl, key) => {
                        // success
                        delete attachment.file;
                        delete attachment.attachment_uuid;
                        if ("error" in attachment) {
                            delete attachment.error;
                        }
                        attachment.file_path = key;
                        attachment.public_url = `${preSignedUrl}/${key}`;
                        resolve();
                    },
                    (error) => {
                        // error
                        console.error("public upload error:", error);
                        attachment.error = true;
                        reject({error, attachment});
                    }
                );
            }));
        }
    }

    return Promise.allSettled(queue);
}


function LoggedInUserModal({loggedInLink}) {
    const [value, setValue] = useState('self');
    const history = useHistory();
    const organizationId = useOrganizationId();
    const { publicOrgId } = usePublicContext();

    return (
        <SimpleModal
            title="Form Submission"
            footer="affirmative"
            hideCloseButton
            onAffirmative={() => {
                if (value === "self") {
                    // If user is currently logged in to the same org as the public form, do a
                    // client-side redirect, otherwise, set location directly so org is properly setup.
                    if (organizationId === publicOrgId) {
                        history.replace(loggedInLink);
                    } else {
                        window.location.href = loggedInLink;
                    }
                } else {
                    // log out user and refresh
                    logoutAndCleanupState()
                    .then(() => getStore().persistor.purge())
                    .then(() => {
                        location.reload();
                    })
                }
            }}
        >
            <p className="zero-blue">How do you want to submit this form?</p>
            <Radio.Group onChange={e => setValue(e.target.value)} value={value}>
                <Space direction="vertical">
                    <Radio value="self">As myself (keep me logged in)</Radio>
                    <Radio value="anon">As an anonymous user (log me out first)</Radio>
                </Space>
            </Radio.Group>
        </SimpleModal>
    );
}

function usePageDataLoader() {
    const { formId, submissionId } = useParams();
    const { publicOrgId } = usePublicContext();
    const dataLoader = useAsyncDataLoader();

    const setupSpecialFormFields = async (form) => {
        if (form.special_fields_data) {
            for (const [key, data] of Object.entries(form.special_fields_data)) {
                if (key === "directory") {
                    const cache = new DirectoryCache(publicOrgId);
                    await cache.bulkSet(data);
                } else {
                    const cache = new DynamicListCache(publicOrgId);
                    await cache.set(key, data);
                }
            }
        }
    };

    const loadSubmissionDraft = async () => {
        const token = getQueryParam('token', '');
        const res = await publicGetSubmission(submissionId, token);
        const submission = await res.json();
        if (!submission.draft) {
            throw new SubmissionPublishedError();
        }
        await setupSpecialFormFields(submission.form);
        return {
            form: submission.form,
            formFields: submission.form.fields,
            pages: generateFormPages(submission.form.fields),
            submission,
        }
    };

    const loadForm = async () => {
        const formRes = await publicGetForm(formId);
        const form = await formRes.json();
        await setupSpecialFormFields(form);
        return {
            form: form,
            formFields: form.fields,
            pages: generateFormPages(form.fields),
            submission: null,
        }
    };

    useEffect(() => {
        if (formId) {
            dataLoader.load(loadForm);
        } else if (submissionId) {
            dataLoader.load(loadSubmissionDraft);
        }
    }, [formId, submissionId]);

    return useMemo(() => {
        let dataType = "unknown";
        if (formId) {
            dataType = "form";
        } else if (submissionId) {
            dataType = "submission";
        }

        return {
            ...dataLoader,
            dataType,
        }
    }, [dataLoader, formId, submissionId]);
}


export default function PublicFormSubmissionPage() {
    const history = useHistory();
    const { formId, teamId } = useParams();
    const {publicOrgId} = usePublicContext();
    const dataLoader = usePageDataLoader();
    const {form, pages, formFields: initialFormFields, submission} = dataLoader.data ?? {};
    const [fetchPageDataError, setFetchPageDataError] = useState("");

    const user = useZeroSelector(state => state.user.user);
    const organizations = useZeroSelector(state => state.org_helper.organizations);
    const teams = useZeroSelector((state) => state.teams_helper.teams);
    const team = useZeroSelector((state) => state.teams_helper.team);

    const [answers, setAnswers] = useState([]);
    const [scoring, setScoring] = useState({ numerator: 0, denominator: 0, score: 0 });
    const [page, setPage] = useState(0);
    const [submissionState, setSubmissionState] = useState(/** @type {PublicFormSubmissionState} */("none"));
    const [submitIsDisabled, setSubmitIsDisabled] = useState(false);
    const [formFields, setFormFields] = useState(initialFormFields ?? []);
    const [refreshKey, setRefreshKey] = useState(1);
    const [hasUploadErrors, setHasUploadErrors] = useState(false);
    const [submissionError, setSubmissionError] = useState("");
    const [hasBeenModified, setHasBeenModified] = useState(false);
    const [showSaveDraftModal, setShowSaveDraftModal] = useState(false);

    const isSubmitting = submissionState !== "none";

    useEffect(() => {
        const { status, error, dataType, data } = dataLoader;

        if (!error) {
            setFetchPageDataError("");
        } else {
            let errorDataType = dataType === 'unknown' ? "data" : dataType;
            let errorMessage = 'Please check your link and try again.';
            if (error instanceof Response) {
                error.json()
                .then(({detail}) => {
                    errorMessage = detail;
                })
                .finally(() => {
                    setFetchPageDataError(`Could not load ${errorDataType}. ${errorMessage}`);
                });
            } else if (error instanceof SubmissionPublishedError) {
                setFetchPageDataError("This draft has already been submitted. You can no longer edit this draft.");
            } else {
                setFetchPageDataError(`Could not load ${errorDataType}. ${errorMessage}`);
            }
        }

        if (status === "success") {
            if (data.submission) {
                setAnswers(data.submission.fields ?? []);
            }
            setFormFields(data.formFields);
            setRefreshKey((key) => key + 1);
        }
    }, [dataLoader]);

    const updateAnswers = (newAnswers) => {
        if (isSubmitting) {
            return;
        }
        setHasBeenModified(true);
        setSubmitIsDisabled(false);
        setAnswers(newAnswers);
    }

    const onSubmitOrSaveDraft = async (commit = false, draftEmail = '') => {
        if (isSubmitting) {
            return;
        }

        const changeSubmissionState = (newState) => {
            if (newState === "none" || commit) {
                setSubmissionState(newState);
            }
        };

        setSubmitIsDisabled(false);
        if (commit) {
            const [hasBlankRequiredFields, newFormFields] = submissionHasBlankRequiredFields(initialFormFields, answers);
            if (hasBlankRequiredFields) {
                setFormFields(newFormFields);
                setRefreshKey((key) => key + 1);
                changeSubmissionState("none");
                setSubmitIsDisabled(true);
                setSubmissionError("Please complete required fields.");
                return;
            }
        }

        changeSubmissionState("attachments");
        const results = await uploadPublicAttachments(answers, form.form_uuid);
        const failedUploads = results.filter(res => res.status === "rejected");
        if (failedUploads.length > 0) {
            changeSubmissionState("none");
            setHasUploadErrors(true);
            NotificationAlert('error', '', 'Could not upload all attachments.');
        } else {
            changeSubmissionState("submitting");
            setHasUploadErrors(false);

            const body = {
                commit,
                fields: answers,
                public_email: commit ? '' : draftEmail,
            };
            
            try {
                const makeRequest = () =>
                    submission
                        ? publicUpdateSubmission(submission.submission_uuid, submission.public_access_token, body)
                        : publicSubmitForm(formId, teamId, body);
                const res = await makeRequest();
                const draft = await res.json();
                if (!commit) {
                    setShowSaveDraftModal(false);
                    setHasBeenModified(false);
                    NotificationAlert("success", "", "Email sent");
                    history.replace(`/public/${teamId}/forms/submissions/${draft.submission_uuid}?token=${draft.public_access_token}`);
                }
                changeSubmissionState("submitted");

            } catch (err) {
                console.error(err);

                if (err instanceof Response) {
                    const errorMessages = await extractErrorMessages(err.clone());
                    const publicEmailErrors = errorMessages.filter(msg => msg.field === 'public_email');
                    if (publicEmailErrors.length > 0) {
                        throw publicEmailErrors[0].message;
                    }
                }

                const defaultErrorMessage = commit ? "Could not submit form." : "Could not save draft.";
                const message = await getErrorMessageFromResponse(err, defaultErrorMessage)
                changeSubmissionState("none");
                setSubmissionError(message);
            }
        }
    };

    const onSubmit = () => onSubmitOrSaveDraft(true);
    const onSaveDraft = (email) => onSubmitOrSaveDraft(false, email);

    const showLoggedInModal = (
        !isPublicUser(user)
        && dataLoader.status === "success"
        && (organizations.map(org => org.organization_uuid).includes(publicOrgId))
        && (teams.map(team => team.uuid).includes(teamId))
    );

    const showSaveDraftAction = (
        hasBeenModified
        && submissionState === "none"
    );

    return (
        <>
            {showLoggedInModal &&
                <LoggedInUserModal
                    loggedInLink={`/${publicOrgId}/home/team/${teamId}/${form.form_type === FormType.LMS ? "courses" : "forms"}/${form.form_uuid}/submit`}
                />
            }
            {showSaveDraftModal &&
                <SaveDraftModal
                    onCancel={() => setShowSaveDraftModal(false)}
                    onSaveDraft={onSaveDraft}
                />
            }
            <MainContent addRoomForHelpWidget>
                {dataLoader.status === "error" && (
                    <PageHead>
                        <BorderedPanel className="zero-blue">
                            <Result
                                status="404"
                                title="Uh oh!"
                                subTitle={fetchPageDataError}
                            />
                        </BorderedPanel>
                    </PageHead>
                )}
                {dataLoader.status === "success" && (
                    <>
                        <PageHead>
                            <BorderedPanel className="zero-blue mar-btm-0" noPadding>
                                { isSubmitting &&
                                    <PublicFormConfirmation
                                        formType={form.form_type}
                                        submissionState={submissionState}
                                    />
                                }
                                { !isSubmitting &&
                                    <div className="flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                        <div style={{ width: "100%", borderRight: "1px solid #e2e2e2", padding: "1.2rem" }}>
                                            <h3 className="page-title zero-blue">{form.name}</h3>
                                            <p
                                                className="header"
                                                style={{ color: "#505050", marginBottom: "0px", paddingLeft: "2px" }}
                                            >
                                                <span>Submitting to {team.name}.</span>
                                            </p>
                                        </div>
                                        <div style={{ minWidth: "12rem" }}>
                                            <p className="zero-blue text-center" style={{ margin: "0 auto" }}>
                                                <b>Score</b>
                                                <br />
                                                {scoring.denominator === 0
                                                    ? "--"
                                                    : `${scoring.numerator} / ${scoring.denominator} (${scoring.score}%)`}
                                            </p>
                                        </div>
                                    </div>
                                }
                            </BorderedPanel>
                        </PageHead>
                        { !isSubmitting &&
                            <PageContent>
                                <BorderedPanel className="zero-blue">
                                    { submissionState === "none" &&
                                        <>
                                            { submission &&
                                                <>
                                                    <span></span> {/* Nifty alters the border radius of the first child in a panel. This is an easy way to avoid the alert from having mismatched border radius. */}
                                                    <Alert
                                                        message={"Note: this is a saved draft on a public link. Please make sure only one person is editing this draft at a time, or you could overwrite changes made by other users."}
                                                    />
                                                </> 
                                            }
                                            <PageSelectDropdown
                                                pageCount={pages.length ?? 0}
                                                currentPage={(page ?? 0) + 1}
                                                onPageChange={setPage}
                                            />
                                            <FormFieldsRenderer
                                                refreshKey={refreshKey}
                                                className="pad-no"
                                                form_uuid={formId}
                                                submission_uuid={null}
                                                form_fields={formFields}
                                                field_answers={answers}
                                                pages={pages}
                                                page={page}
                                                is_submission={true}
                                                toolbar_items={[]}
                                                isSubmissionView={false}
                                                updateAnswers={updateAnswers}
                                                updateScore={(numerator, denominator, score) => {
                                                    setScoring({ numerator, denominator, score });
                                                }}
                                            />

                                            { hasUploadErrors &&
                                                <div className="text-center">
                                                    <small className="error">Not all attachments could be uploaded. Please try again or remove attachments highlighted in red.</small>
                                                </div>
                                            }

                                            { submissionError &&
                                                <Alert
                                                    className="mar-btm-10"
                                                    message={<span className="text-semibold">Submission Error</span>}
                                                    description={submissionError}
                                                    type="error"
                                                    showIcon
                                                    icon={<AlertOutlined />}
                                                />
                                            }

                                            <FormSubmissionControls
                                                isDiscarding={false}
                                                onDiscard={() => {}}
                                                currentPage={page}
                                                pages={pages}
                                                setPage={setPage}
                                                onSubmit={onSubmit}
                                                isSubmitDisabled={isSubmitting || submitIsDisabled}
                                                isSubmitting={isSubmitting}
                                            />
                                        </>
                                    }
                                </BorderedPanel>
                                { showSaveDraftAction &&
                                    <button
                                        className="ButtonLink btn-link pull-right"
                                        style={{margin: '1rem 2rem 0 0'}}
                                        onClick={() => setShowSaveDraftModal(true)}
                                    >
                                        Save Draft
                                    </button>
                                }
                            </PageContent>
                        }
                    </>
                )}
            </MainContent>
        </>
    );
}
