// @ts-check
import { AlertOutlined } from '@ant-design/icons';
import { Alert, Popconfirm } from "antd";
import ButtonLink from "components/Shared/ButtonLink";
import LogicLessSyncSpinner from "components/Shared/LogicLessSyncSpinner";
import useOrganizationId from "hooks/useOrganizationId";
import { syncLocalDraft, useDraftsCurrentlySyncing } from "offline/SubmissionDraftsCache";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useOfflineDataCachesContext } from "../OfflineDataCaches";

/**
 * @param {{
 *  submission: SubmissionDraft
 *  showTemplate?: boolean;
 *  onSyncClicked?: () => void;
 *  onSync?: (success: boolean) => void;
 *  onDelete?: () => void;
 *  onEditClicked?: () => void;
 * }} props 
 */
export default function SubmissionSyncErrorAlert({submission, showTemplate, onSyncClicked, onSync, onDelete, onEditClicked}) {
    const submissionId = submission?.submission_uuid;
    const orgId = useOrganizationId();
    const {submissionDrafts: {cache}} = useOfflineDataCachesContext();
    const draftsCurrentlySyncing = useDraftsCurrentlySyncing();
    const [isSyncing, setIsSyncing] = useState(draftsCurrentlySyncing.ids.includes(submissionId));

    useEffect(() => {
        return draftsCurrentlySyncing.subscribe(({detail}) => {
            const {id, action} = detail;
            if (id === submissionId) {
                setIsSyncing(action === "add");
            }
        });
    }, [draftsCurrentlySyncing.subscribe]);

    if (!submission || !submission.$error) {
        return null;
    }

    const retrySync = async () => {
        onSyncClicked?.();
        setIsSyncing(true);
        const error = await syncLocalDraft(cache, submission, {
            forceSync: true,
            forceSubmission: submission.$error === "submit",
        });
        setIsSyncing(false);
        onSync?.(!error);
    };

    const deleteDraft = () => {
        cache.delete(submissionId, {immediate: true, includeAttachments: true})
        .then(() => onDelete?.());
    };

    const linkUrl = `/${orgId}/home/team/${submission.team.uuid}/forms/${submission.form.form_uuid}/submission/${submission._id}`;

    const fullDescription = (
        <span className="zero-dark-grey">
            <p className="mar-no">A sync error occurred: {getErrorMessage(submission)}</p>
            { showTemplate &&
                <p className="mar-no">Template: {submission.form.name}</p>
            }
            { isSyncing ? (
                <LogicLessSyncSpinner className="flex" style={{alignItems: 'center'}} />
            ): (
                <p className="mar-no">
                    <ButtonLink onClick={retrySync}>Retry sync</ButtonLink>
                    {" "}|{" "}
                    { onEditClicked ? (
                        <ButtonLink onClick={onEditClicked}>Edit draft</ButtonLink>
                    ) : (
                        <Link to={linkUrl} className="blue-link">Edit draft</Link>
                    )}
                    {" "}|{" "}
                    <Popconfirm
                        title="Delete draft?"
                        description="Are you sure you want to delete this draft?"
                        onConfirm={deleteDraft}
                        okText="Yes"
                        cancelText="No"
                    >
                        <ButtonLink color="red">Delete draft</ButtonLink>
                    </Popconfirm>
                </p>
            )}
        </span>
    );

    return (
        <Alert
            className="mar-btm-10"
            message={<span className="text-semibold">Sync Error</span>}
            description={fullDescription}
            type="warning"
            showIcon
            icon={<AlertOutlined />}
        />
    )
}

/**
 * @param {SubmissionDraft} submission 
 * @returns {string}
 */
function getErrorMessage(submission) {
    let msg = submission.$errorMessage;

    if (!msg) {
        return "Unknown error.";
    }

    // Get rid of "Error: " at beginning of message
    msg = msg.replace(/^Error: /, "");

    // Add period at end if necessary
    if (!msg.endsWith(".")) {
        msg += ".";
    }

    return msg;
}