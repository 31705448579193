export const AgGridFilter = {
    text: {
        filter: true,
        filterParams: {
            maxNumConditions: 1,
        },
    },
    textNoBlankOptions: {
        filter: true,
        filterParams: {
            maxNumConditions: 1,
            filterOptions: [
                "contains",
                "notContains",
                "equals",
                "notEqual",
                "startsWith",
                "endsWith",
            ],
        },
    },
    textNullable: {
        filter: true,
        filterParams: {
            maxNumConditions: 1,
            filterOptions: [
                "contains",
                "notContains",
                "equals",
                "notEqual",
                "startsWith",
                "endsWith",
                {
                    displayKey: 'null',
                    displayName: 'Blank',
                    numberOfInputs: 0,
                    predicate: () => true,
                },
                {
                    displayKey: 'notNull',
                    displayName: 'Not blank',
                    numberOfInputs: 0,
                    predicate: () => true,
                },
            ],
        },
    },
    date: {
        filter: "agDateColumnFilter",
        filterParams: {
            maxNumConditions: 1,
        },
    },
    contains: {
        filter: true,
        filterParams: {
            maxNumConditions: 1,
            filterOptions: ["contains", "notContains"],
        },
    },
};