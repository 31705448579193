// @ts-check
import ZeroDataGrid from "components/Shared/ZeroDataGrid";
import { useSubmissionDataGridContext } from "./SubmissionDataGrid.context";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import { isEqual } from "lodash";
import { EventNames, getColumnDefs, loadSubmissions } from "./SubmissionDataGrid.utils";
import useZeroSelector from "hooks/useZeroSelector";
import { objectIsEmpty } from "other/Helper";
import { useZeroEventListener } from "components/ZeroContext";


const SUBMISSIONS_PER_PAGE = 25;

export default function SubmissionDataGrid({formType}) {
    const location = useLocation();
    const {gridRef, settings, setSettings, rowCount} = useSubmissionDataGridContext();
    const formsQuery = useZeroSelector(state => state.forms.query);
    const periodQuery = useZeroSelector(state => state.forms.period_query);

    const colDefs = useMemo(getColumnDefs, []);

    const getRows = useCallback(async (/** @type {AgGrid.IGetRowsParams} */params) => {
        const perPage = SUBMISSIONS_PER_PAGE;
        const currentPage = Math.floor((params.startRow ?? 0) / perPage) + 1;

        const queryParams = new URLSearchParams(formsQuery + periodQuery);
        queryParams.set('page', String(currentPage));
        queryParams.set('full_data', 'false');
        queryParams.set('form_types', formType);

        if (!objectIsEmpty(params.filterModel)) {
            queryParams.set('ag_filter', JSON.stringify(params.filterModel));
        }

        const gridApi = gridRef.current?.api;
        if (rowCount === null) {
            gridApi?.setGridOption("loading", true);
        }
        const {submissions, totalSubmissions} = await loadSubmissions(queryParams);
        params.successCallback(submissions, totalSubmissions);
        if (rowCount === null) {
            gridApi?.setGridOption("loading", false);
        }
    }, [rowCount, formsQuery, periodQuery, formType]);

    const onGridReady = useCallback((/** @type {AgGrid.GridReadyEvent} */ event) => {
        /** @type {AgGrid.IDatasource} */
        const dataSource = {
            rowCount: undefined,
            getRows,
        };
        event.api.setGridOption("datasource", dataSource);
    }, [getRows]);

    useEffect(() => {
        const agApi = gridRef.current?.api;
        if (agApi) {
            const datasource = {
                rowCount: undefined,
                getRows,
            };
            agApi.setGridOption("datasource", datasource);
        }
    }, [formsQuery, periodQuery, gridRef, formType]);

    const onStateUpdated = useCallback((/** @type {AgGrid.StateUpdatedEvent} */ event) => {
        if (event.sources.includes("columnOrder")) {
            // @ts-ignore
            const columnDefsOrder = colDefs.map(def => def.field);
            const newColumnOrder = event.state.columnOrder.orderedColIds;
            const isDefaultOrder = isEqual(columnDefsOrder, newColumnOrder);
            if (isDefaultOrder) {
                setSettings('columnOrder', null);
            } else {
                setSettings('columnOrder', event.state.columnOrder);
            }
        }
        
        if (event.sources.includes("sort")) {
            setSettings('sort', event.state.sort);
        }
    }, [setSettings]);

    const resetTable = useCallback(() => {
        const grid = gridRef.current;
        if (!grid) {
            return;
        }
        setSettings('columnOrder', null);
        grid.api.resetColumnState();
    }, [setSettings]);

    const refreshData = useCallback(() => {
        const grid = gridRef.current;
        if (!grid) {
            return;
        }
        grid.api.refreshInfiniteCache();
    }, []);

    useZeroEventListener(EventNames.RESET_TABLE, resetTable);
    useZeroEventListener(EventNames.REFRESH_DATA, refreshData);

    return (
        <ZeroDataGrid
            gridRef={gridRef}
            columnDefs={colDefs}
            defaultColDef={{
                cellStyle: {
                    display: 'flex',
                    alignItems: 'center',
                }
            }}
            initialState={location.state?.gridState ?? settings}
            onGridReady={onGridReady}
            onStateUpdated={onStateUpdated}
            cacheBlockSize={SUBMISSIONS_PER_PAGE}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={2}
            infiniteInitialRowCount={rowCount ?? SUBMISSIONS_PER_PAGE}
            pagination={true}
            paginationPageSizeSelector={false}
            paginationPageSize={rowCount ?? SUBMISSIONS_PER_PAGE}
            tooltipShowDelay={500}
            tooltipShowMode="standard"
            suppressCellFocus
        />
    )
}